import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  AdminUser,
  AdminUserListResponse,
  CreateAdminUserRequest,
  CreateAdminUserResponse,
  GetAdminUserResponse,
  PaginatedListRequest,
  UpdateAdminUserRequest,
  UpdateAdminUserResponse,
  UpdateAdminUserRulesRequest,
  UserRules,
} from '@naus-code/naus-admin-types';

import { rootApi } from '~/store/redux.utils';

export const usersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserAdminAllList: builder.query<AdminUserListResponse, void>({
      query: () => ({
        url: `admin-user/get-full-list/`,
        method: 'GET',
      }),
      providesTags: ['*', 'User_List'],
    }),

    getUserAdminList: builder.query<AdminUserListResponse, PaginatedListRequest>({
      query: (data) => ({
        url: `admin-user/get-list/`,
        method: 'POST',
        data,
      }),
    }),

    getUserSupportList: builder.query<AdminUserListResponse, PaginatedListRequest>({
      query: (data) => ({
        url: `admin-user/get-support-list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'User_Support_List'],
    }),

    getUserItem: builder.query<GetAdminUserResponse, string>({
      query: (id) => ({
        url: `admin-user/get-adminUser/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'User_Item'],
    }),

    createUser: builder.mutation<CreateAdminUserResponse, CreateAdminUserRequest>({
      query: (data) => ({
        url: `admin-user/create-admin-user`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'User_Update_Config',
        'User_Item',
        'User_Support_List',
        'User_List',
        'assignAdminUserFormConfig',
      ],
    }),

    getUserConfigCreate: builder.query<ConfigForData, void>({
      query: () => ({
        url: `admin-user/form-config/create-admin-user`,
        method: 'GET',
      }),
      providesTags: ['*', 'User_Create_Config'],
    }),

    getUserConfigUpdate: builder.query<ConfigForData, string>({
      query: (id: string) => ({
        url: `admin-user/form-config/update-admin-user/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'User_Update_Config'],
    }),

    updateUser: builder.mutation<UpdateAdminUserResponse, UpdateAdminUserRequest>({
      query: (data) => ({
        url: `admin-user/update-admin-user`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'User_Update_Config',
        'User_Item',
        'User_Support_List',
        'User_List',
        'assignAdminUserFormConfig',
      ],
    }),

    getUserRulesConfigUpdate: builder.query<ConfigForData, string>({
      query: (id: string) => ({
        url: `admin-user/form-config/update-admin-user-rules/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'User_Update_Config'],
    }),

    updateUserRules: builder.mutation<
      UpdateAdminUserRulesRequest,
      UpdateAdminUserRulesResponse
    >({
      query: (data) => ({
        url: `admin-user/update-admin-user-rules`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'User_Update_Config',
        'User_Item',
        'User_Support_List',
        'User_List',
        'assignAdminUserFormConfig',
      ],
    }),

    // @Get('/get-admin-user-managees/', [applyRoleValidation(['SYSTEM', 'CUSTOMER_SUPPORT_LEAD'])])
    // getCustomerSupportTeamLeadManagees(@Params('id') id: string): Promise<GetAdminUserResponse[]> {
    //     return this._adminUserService.getAdminUserManagees(id);
    // }

    getCustomerSupportTeamLeadManagees: builder.query<
      GetAdminUsersWithActivityStatusResponse,
      void
    >({
      query: () => ({
        url: `admin-user/get-admin-user-managees/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getCustomerSupportTeamLeadManagees'],
    }),

    getConnectedSockets: builder.query<string[], void>({
      query: () => ({
        url: `admin-user/get-connected-sockets/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getConnectedSockets'],
    }),

    getUserFromSocketId: builder.query<AdminUser, string>({
      query: (id: string) => ({
        url: `admin-user/get-user-from-socket/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getUserIdFromSocketId'],
    }),
  }),
});

export interface UpdateAdminUserRulesResponse {
  adminUser: {
    _id: string;
    rules: {
      userRules: UserRules;
    };
  };
}
export interface GetAdminUserWithActivityStatusResponse {
  adminUser: AdminUser;
  isOnline: boolean;
}

export type GetAdminUsersWithActivityStatusResponse = {
  managees: GetAdminUserWithActivityStatusResponse[];
};
