import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestListItemResponse } from '@naus-code/naus-admin-types';
import differenceBy from 'lodash/differenceBy';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RefreshControl } from 'react-native-gesture-handler';

import { FlatList } from '~/components/FlatList/FlatList';
import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { paginationUtil } from '~/components/FlatList/FlatList.Paginated';
import { useAppNavigation } from '~/hooks/nav.hook';
import { useGetAuthUser, useGetAuthUserRules } from '~/screens/Auth/Auth.Context';
import { usersApi } from '~/screens/Users/Users.api';
import { translate } from '~/utils/translation.utils';
import {
  // newSupportRequestAssignedToast,
  playNotificationAlert,
  showWebNotification,
} from '~/utils/utils.notification';

import { SupportRequestListItemComponent } from '../SupportRequest.ListItem';
import { supportRequestApi } from '../SupportRequests.api';
import {
  filterAdminuser,
  filterCategory,
  filterUserId,
} from './SupportRequestPending.utils';
import { PendingSupportRequestsListHeader } from './SupportRequestsPending.ListHeader';

export interface SupportRequestListProps {
  onPress: (item: SupportRequestListItemResponse) => void;
  supportRequestId?: string;
}

export const SupportRequestListNative = () => {
  const navigate = useAppNavigation();
  return (
    <SupportRequestList
      onPress={(item) => {
        navigate.navigate('SupportRequest-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const SupportRequestList = (props: SupportRequestListProps) => {
  const { spacing } = useAppTheme();
  const { currentUser } = useGetAuthUser();
  const canAssign = useGetAuthUserRules('assignSupportRequests');
  const { data: userData } = usersApi.useGetUserItemQuery(currentUser?.uid || '', {
    skip: !currentUser?.uid,
  });

  const { list, Search, isLoading, isFetching, refetch } = useSearchState({
    hook: supportRequestApi.useGetSupportRequestPendingQuery,
    style: {
      height: 47,
    },
    keyToList: 'supportRequests',
    arg: {
      sortBy: 'dateRegarding',
    },
    options: {
      pollingInterval: 5 * 60 * 1000,
      refetchOnFocus: true,
    },
  });

  const [displayedList, setDisplayedList] = useState(list);
  const [category, setCategory] = useState('');
  const [onlyOwn, setOnlyOwn] = useState(false);
  const [userIds, setUserIds] = useState(new Set<string>());

  const oldRef = useRef<SupportRequestListItemResponse[]>([]);

  const filterDisplayedListByCategory = (
    category: string,
    list: SupportRequestListItemResponse[],
    userIds: Set<string>,
    onlyOwn: boolean,
  ) => {
    const filteredList = [] as SupportRequestListItemResponse[];
    setCategory(category);
    setUserIds(userIds);

    if (!onlyOwn && userIds.size === 0 && category === '') {
      //at least one of the conditions is active go through
      setDisplayedList(list);
      return;
    }
    for (const supportItem of list) {
      if (filterCategory(supportItem, category)) {
        if (canAssign && filterUserId(supportItem, userIds)) {
          filteredList.push(supportItem);
          continue;
        }
        if (!canAssign && filterAdminuser(supportItem, onlyOwn, userData)) {
          filteredList.push(supportItem);
          continue;
        }
      }
    }
    setDisplayedList(filteredList);
  };

  if (oldRef.current.length) {
    const diff = differenceBy(
      list,
      oldRef.current,
      'id',
    ) as SupportRequestListItemResponse[];
    if (diff.length) {
      // newSupportRequestAssignedToast(diff);
      playNotificationAlert();
      showWebNotification({ message: 'New support request' });
    }
  }

  if (list?.length) {
    oldRef.current = list;
  }

  useEffect(() => {
    filterDisplayedListByCategory(category, list, userIds, onlyOwn);
  }, [isLoading, list]);
  const currentSupportReqId = props.supportRequestId || '';

  const renderItem = useCallback(
    (supportRequest: SupportRequestListItemResponse) => {
      return (
        <SupportRequestListItemComponent
          selected={currentSupportReqId === supportRequest.id}
          key={supportRequest.id}
          supportRequest={supportRequest}
          onPress={() => {
            props.onPress(supportRequest);
          }}
        />
      );
    },
    [currentSupportReqId],
  );
  const uniqueCategories = new Set<string>();
  const uniqueUserIds = {};
  list.forEach((item: SupportRequestListItemResponse) => {
    uniqueCategories.add(item.title);
    if (item.adminId) {
      uniqueUserIds[item.adminId] = item.adminUserName;
    }
  });

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <PendingSupportRequestsListHeader
        filterProps={{
          filterActive: category !== '' || onlyOwn || userIds.size > 0,
          onlyOwn,
          uniqueCategories: Array.from(uniqueCategories),
          uniqueUserIds: Object.entries(uniqueUserIds),
        }}
        onFilter={({
          category,
          userIds,
          showOwnRequestsOnly,
        }: {
          category: string;
          userIds: Set<string>;
          showOwnRequestsOnly: boolean;
        }) => {
          filterDisplayedListByCategory(category, list, userIds, showOwnRequestsOnly);
          setOnlyOwn(onlyOwn);
          setUserIds(userIds);
          setCategory(category);
        }}
        count={displayedList.length}
        refetch={() => {
          refetch();
        }}
        isLoading={isFetching}
      />
      {Search}
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        feedback={translate('supportRequests.emptySupportRequest')}
        isLoading={isLoading}
        data={displayedList}
        simple
        renderItem={renderItem}
        onPressItem={(v) => {
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};

export const useInvalidatePausedSupportRequestList = () => {
  const [invalidatePausedSupportRequestList, { isLoading }] =
    supportRequestApi.useInvalidateGetSupportRequestPausedMutation();
  return {
    invalidate: async () => {
      const lastItemId = paginationUtil.lists.SupportRequestsPaused;
      if (lastItemId) {
        await invalidatePausedSupportRequestList({
          paginationRequest: {
            itemCount: 0,
            refreshList: true,
            lastItemId,
          },
          sortBy: 'dateCreated',
        });
      }
    },
    isLoading,
  };
};
