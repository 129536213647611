import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  copyToClipboard,
  isMobile,
  isNativeMobile,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  PurchaseClientWithMetaWithTickets,
  PurchaseListItemResponse,
} from '@naus-code/naus-admin-types';
import { Image, Pressable } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { LiveDateText } from '~/components/DateText';
import { Globals } from '~/components/Globals/Globals';
import { Pill } from '~/components/Pill/Pill';
import { usePurchaseCompanyContactDetails } from '~/components/Purchases/Modals/PurchaseCompanyContactDetails';
import { usePurchaseTrackingInfo } from '~/components/Purchases/Modals/PurchaseTrackingInfo';
import { AppText } from '~/components/Text';
import { useAppNavigation } from '~/hooks/nav.hook';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { isPurchaseClientWithMetaWithTickets } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.utils';
import { PurchasePDF } from '~/screens/Support/Purchases/PurchaseCommon/Purchase.PDF';
import {
  PurchaseOpenPill,
  PurchaseSplitPill,
  PurchaseVoidPill,
} from '~/screens/Support/Purchases/PurchaseCommon/Purchase.Pill';
import { translate } from '~/utils/translation.utils';

import {
  getIdFromPurchase,
  getPriceWithSymbol,
  getPurchasePdfId,
  getPurchaseWithHistory,
  isNonPurchaseListItem,
  PurchaseMix,
} from './purchase.utils';
import { PurchaseTrackingInfo } from './PurchaseItem';
import { purchaseApi } from './Purchases.api';

export interface PurchaseListItemComponentProps {
  purchase: PurchaseListItemResponse;
  onPress: () => void;
}

export const PurchaseListItemComponent = ({
  purchase,
  onPress,
}: PurchaseListItemComponentProps) => {
  const { shading } = useAppTheme();
  return (
    <>
      {isMobile ? (
        <TouchableOpacity onPress={onPress}>
          <View
            br={10}
            b={2}
            bg="white"
            bc="monochrome-extraLight"
            p="2.5"
            style={[{ overflow: 'hidden' }]}
          >
            <PurchaseListItemInternalComponent purchase={purchase} />
          </View>
        </TouchableOpacity>
      ) : (
        <Pressable onPress={onPress}>
          {({ hovered }: ButtonInteractionState) => {
            return (
              <View
                br={10}
                p="2.5"
                bg={hovered ? 'primary-base' : 'white'}
                style={[{ overflow: 'hidden' }, shading.light]}
              >
                <PurchaseListItemInternalComponent purchase={purchase} />
              </View>
            );
          }}
        </Pressable>
      )}
    </>
  );
};

export const PurchaseListItemInternalComponent = ({
  purchase,
  supportId,
  withActions,
  compareMode,
  purchaseTrackingInfo,
  isPrinted,
}: {
  purchase: PurchaseMix;
  withActions?: boolean;
  supportId?: string;
  compareMode?: boolean;
  isPrinted?: boolean;
  purchaseTrackingInfo?: PurchaseTrackingInfo;
}) => {
  return (
    <VStack space="1.5" flex={isNativeMobile ? 1 : undefined}>
      <PurchaseHeader purchase={purchase} clickable={withActions} />
      <PurchaseDetails
        purchase={purchase}
        withActions={withActions}
        supportId={supportId}
      />
      <PurchaseInfo
        purchase={purchase}
        compareMode={compareMode}
        purchaseTrackingInfo={purchaseTrackingInfo}
        isPrinted={isPrinted}
      />
    </VStack>
  );
};

const PurchaseHeader = ({
  purchase,
  clickable,
}: {
  purchase: PurchaseMix;
  clickable?: boolean;
}) => {
  const { operatorImgUrl, operator, companyId, departureTimestamp, vesselName } =
    purchase;

  const { open } = usePurchaseCompanyContactDetails();

  return (
    <HStack space="2.5" flex={1}>
      <Pressable disabled={!clickable} onPress={() => open(purchase)}>
        <CompanyImage imageUrl={operatorImgUrl} />
      </Pressable>
      <HStack flex={1} space="2.5">
        <VStack>
          <Text.ExtraSmall numberOfLines={1} color="monochrome-dark">
            {vesselName}
          </Text.ExtraSmall>
          <Text.ExtraSmall
            numberOfLines={1}
            color="monochrome-mid"
            style={{ overflow: 'hidden' }}
          >{`${operator} (${companyId})`}</Text.ExtraSmall>
        </VStack>
        <SubDistributionPill purchase={purchase} />
        {/* <View flex={1} /> */}
        <HStack
          style={{
            justifyContent: 'flex-end',
            flex: 1,
            gap: 8,
            flexWrap: 'wrap-reverse',
          }}
        >
          <PurchaseSplitPill purchase={purchase} />
          <PurchaseVoidPill purchase={purchase} />
          <PurchaseOpenPill purchase={purchase} />
          <HStack space="1">
            <Text.Small color="monochrome-extraDark">{'('}</Text.Small>
            <LiveDateText
              align="right"
              numberOfLines={1}
              dateTime={departureTimestamp}
              formatOption="duration"
              type="small"
              color="monochrome-extraDark"
            />
            <Text.Small color="monochrome-extraDark">{')'}</Text.Small>
          </HStack>
        </HStack>
      </HStack>
    </HStack>
  );
};

export const SubDistributionPill = ({ purchase }: { purchase: PurchaseMix }) => {
  if (hasDistribution(purchase)) {
    if (purchase.subDistributionProvider === 'DIRECT') {
      return null;
    }
    return (
      <VStack>
        <Pill color="success">
          <Text.ExtraSmall
            numberOfLines={1}
            color="success-mid"
            style={{ overflow: 'hidden' }}
          >
            {purchase.subDistributionProvider}
          </Text.ExtraSmall>
        </Pill>
      </VStack>
    );
  }
};

export function hasDistribution(
  purchase: PurchaseMix,
): purchase is PurchaseClientWithMetaWithTickets {
  return 'subDistributionProvider' in purchase;
}

const PurchaseDetails = ({
  purchase,
  withActions,
  supportId,
}: {
  purchase: PurchaseMix;
  withActions?: boolean;
  supportId?: string;
}) => {
  const {
    departureDate,
    arrivalTime,
    departureTime,
    destinationDisplay,
    originDisplay,
    origin,
    destination,
    ccySymbol,
  } = purchase;

  const companyCurrency = getCompanyCcy(purchase);

  return (
    <HStack space="2.5">
      <VStack space="0.5" pt="2" flex={1}>
        <LiveDateText
          align="left"
          numberOfLines={1}
          dateTime={departureDate}
          formatOption="fullDate"
          type="body2Medium"
          color="monochrome-extraDark"
          style={{
            fontSize: 13,
          }}
        />
        <Text.Body2Medium>
          {`${departureTime} ${originDisplay} (${origin}) → ${arrivalTime} ${destinationDisplay} (${destination})`}
        </Text.Body2Medium>
      </VStack>
      <VStack>
        <Text.Body1Regular>
          {getPriceWithSymbol(purchase.value, ccySymbol)}
        </Text.Body1Regular>
        {companyCurrency ? (
          <Text.Small>{`[${getPriceWithSymbol(
            companyCurrency.value,
            companyCurrency.currency,
          )}]`}</Text.Small>
        ) : null}
      </VStack>
      {withActions && isPurchaseClientWithMetaWithTickets(purchase) && (
        <View style={{ position: 'relative' }}>
          <Globals.PurchaseMenu purchase={purchase} supportId={supportId} />
        </View>
      )}
    </HStack>
  );
};

const getCompanyCcy = (purchase: PurchaseMix) => {
  if ('_id' in purchase) {
    const { data } = purchaseApi.useGetPurchaseQuery(purchase._id);
    if (data) {
      return { currency: purchase.companyCcy, value: purchase.valueCompanyCcy };
    }
  }
  return undefined;
};

const PurchaseInfo = ({
  purchase,
  compareMode,
  purchaseTrackingInfo,
  isPrinted,
}: {
  purchase: PurchaseMix;
  compareMode?: boolean;
  purchaseTrackingInfo?: PurchaseTrackingInfo;
  isPrinted?: boolean;
}) => {
  const {
    ticketCount,
    supportCount,
    historyCount,
    providerReservationCode,
    companyReservationCode,
  } = purchase;

  const pdfId = getPurchasePdfId(purchase);
  const purchaseTariff = getPurchaseTariff(purchase);

  return (
    <VStack space="3">
      <VStack space="1">
        <HStack space="1">
          <Text.Small color="monochrome-mid">
            {translate('bookings.providerCode')} -
          </Text.Small>
          <AppText.SmallMedium
            onPress={() => {
              copyToClipboard(providerReservationCode);
            }}
            color="monochrome-dark"
          >
            {providerReservationCode}
          </AppText.SmallMedium>
        </HStack>
        <HStack space="1">
          <Text.Small color="monochrome-mid">
            {translate('bookings.companyCode')} -
          </Text.Small>
          <AppText.SmallMedium
            onPress={() => {
              copyToClipboard(companyReservationCode);
            }}
            color="monochrome-dark"
          >
            {companyReservationCode}
          </AppText.SmallMedium>
        </HStack>
        {purchase.companyAccessCode && (
          <HStack space="1">
            <Text.Small color="monochrome-mid">
              {translate('bookings.companyAccessCode')} -
            </Text.Small>
            <AppText.SmallMedium
              onPress={() => {
                if (purchase.companyAccessCode) {
                  copyToClipboard(purchase.companyAccessCode);
                }
              }}
              color="monochrome-dark"
            >
              {purchase.companyAccessCode}
            </AppText.SmallMedium>
          </HStack>
        )}

        {isNonPurchaseListItem(purchase) ? (
          <HStack space="1">
            <Text.Small color="monochrome-mid">
              {translate('bookings.purchaseTicketType')} -
            </Text.Small>
            <AppText.SmallMedium color="monochrome-dark">
              {purchase.purchaseTicketType}
            </AppText.SmallMedium>
          </HStack>
        ) : null}
        {purchaseTariff && (
          <HStack space="1">
            <AppText.Body1SemiBold color="primary-mid">
              {purchaseTariff}
            </AppText.Body1SemiBold>
          </HStack>
        )}

        {pdfId ? (
          <HStack space="1">
            <Icon color="monochrome-mid" icon="pdf" />
            <AppText.SmallMedium color="monochrome-dark">
              {translate('purchases.hasPdf')}
            </AppText.SmallMedium>
          </HStack>
        ) : null}
      </VStack>
      <IconRow
        purchase={purchase}
        ticketCount={ticketCount}
        historyCount={historyCount}
        supportCount={supportCount}
        purchaseTrackingInfo={purchaseTrackingInfo}
        pdfId={pdfId}
        isPrinted={isPrinted}
        compareMode={compareMode}
      />
    </VStack>
  );
};

const CompanyImage = ({ imageUrl }: { imageUrl?: string }) => {
  return (
    <View br={8} style={[{ overflow: 'hidden' }]}>
      <Image
        source={{
          uri: imageUrl,
        }}
        style={{ width: 30, height: 30 }}
      />
    </View>
  );
};

const IconRow = ({
  purchase,
  ticketCount,
  historyCount,
  supportCount,
  purchaseTrackingInfo,
  pdfId,
  isPrinted,
  compareMode,
}: {
  purchase: PurchaseMix;
  ticketCount: number;
  historyCount: number;
  supportCount: number;
  purchaseTrackingInfo?: PurchaseTrackingInfo;
  isPrinted?: boolean;
  pdfId?: string;
  compareMode?: boolean;
}) => {
  const hasHistory = !!getPurchaseWithHistory(purchase);
  const purchaseHistory = Globals.usePurchaseHistoryViewer();
  const { open: openPurchaseTrackingInfo } = usePurchaseTrackingInfo();
  const { navigate } = useAppNavigation();

  const departureGate = purchaseTrackingInfo?.departureGate;
  const trackId = purchaseTrackingInfo?.trackId;
  const etoNotification = purchaseTrackingInfo?.etoNotification;
  const etaNotification = purchaseTrackingInfo?.etaNotification;
  const actionNotification = purchaseTrackingInfo?.actionNotification;
  const fullRefund = purchase.canAskForFullRefundOverride;
  const holdAction = purchase.holdAction;

  if (compareMode) {
    return <HStack>{pdfId ? <PurchasePDF pdfId={pdfId} /> : null}</HStack>;
  }

  return (
    <HStack space="1">
      {fullRefund && (
        <HStack bg="success-extraLight" br={5} px="1" py="1" style={{ height: '100%' }}>
          <Icon size="small" color="monochrome-extraDark" icon="custom-refundable" />
        </HStack>
      )}

      <HStack bg="primary-extraLight" br={5} px="0.5" py="1">
        <Icon size="small" color="monochrome-extraDark" icon="ticket" />
        <Text.Small color="monochrome-extraDark">{` ${ticketCount}`}</Text.Small>
      </HStack>
      <Pressable
        disabled={!hasHistory || historyCount === 0}
        onPress={() => {
          purchaseHistory.open(purchase);
        }}
      >
        <HStack bg="primary-extraLight" br={5} px="0.5" py="1">
          <Icon size="small" color="monochrome-extraDark" icon="history" />
          <Text.Small color="monochrome-extraDark">{` ${historyCount}`}</Text.Small>
        </HStack>
      </Pressable>
      <Pressable
        onPress={async () => {
          const purchaseId = getIdFromPurchase(purchase);
          navigate('PurchaseSupportRequests', {
            purchaseId,
          });
        }}
      >
        <HStack bg="primary-extraLight" br={5} px="0.5" py="1">
          <Icon size="small" color="monochrome-extraDark" icon="customer-support" />
          <Text.Small color="monochrome-extraDark">{` ${supportCount}`}</Text.Small>
        </HStack>
      </Pressable>

      {/* <Pressable
        onPress={async () => {
          const purchaseId = getIdFromPurchase(purchase);
          navigate('PurchaseSupportRequests', {
            purchaseId,
          });
        }}
      >
        <HStack bg="primary-extraLight" br={5} px="0.5" py="1">
          <Icon size="small" color="monochrome-extraDark" icon="customer-support" />
          <Text.Small color="monochrome-extraDark">{` ${supportCount}`}</Text.Small>
        </HStack>
      </Pressable> */}

      {trackId ? (
        <Pressable
          onPress={() => {
            openPurchaseTrackingInfo(purchaseTrackingInfo);
          }}
        >
          <HStack align="center" space="1" bg="primary-extraLight" br={5} px="0.5" py="1">
            <Icon color="monochrome-extraDark" icon="ferry" />
            <Icon size="small" color="monochrome-extraDark" icon="check" />
            <Icon
              size="small"
              color="monochrome-extraDark"
              icon={actionNotification ? 'check' : 'close'}
            />
            <Icon
              size="small"
              color="monochrome-extraDark"
              icon={etoNotification ? 'check' : 'close'}
            />
            <Icon
              size="small"
              color="monochrome-extraDark"
              icon={etaNotification ? 'check' : 'close'}
            />
          </HStack>
        </Pressable>
      ) : null}
      {departureGate && (
        <HStack>
          <HStack bg="primary-extraLight" br={5} px="0.5" py="1">
            <Icon size="small" color="monochrome-extraDark" icon="gates" />
            <Text.Small color="monochrome-extraDark">{` ${departureGate}`}</Text.Small>
          </HStack>
        </HStack>
      )}
      {pdfId ? <PurchasePDF pdfId={pdfId} /> : null}

      {holdAction ? <ToggleHoldButton purchase={purchase} /> : null}
      {isPrinted ? <TogglePrintedButton purchase={purchase} /> : null}
    </HStack>
  );
};

const TogglePrintedButton = ({ purchase }: { purchase: PurchaseMix }) => {
  const [togglePrint] = purchaseActionApi.useTogglePrintedMutation();
  return (
    <Pressable
      onPress={async () => {
        const purchaseId = getIdFromPurchase(purchase);
        const response = await togglePrint(purchaseId);
        handleResponse({
          response,
          onSuccess: () => {},
        });
      }}
    >
      <HStack bg="success-extraLight" br={5} px="2" py="1" space="1">
        <Icon size="small" color="monochrome-extraDark" icon="printer" />
        <Text.Small color="monochrome-extraDark">
          {translate('purchases.printed')}
        </Text.Small>
      </HStack>
    </Pressable>
  );
};

const ToggleHoldButton = ({ purchase }: { purchase: PurchaseMix }) => {
  const [toggleHold] = purchaseActionApi.useToggleHoldMutation();

  return (
    <Pressable
      onPress={async () => {
        const purchaseId = getIdFromPurchase(purchase);
        const response = toggleHold(purchaseId);
        handleResponse({
          response,
          onSuccess: () => {},
        });
      }}
    >
      <HStack bg="secondary-extraLight" br={5} px="2" py="1" space="1">
        <Icon size="small" color="monochrome-extraDark" icon="circle-pause" />
        <Text.Small color="monochrome-extraDark">
          {translate('purchases.onHold')}
        </Text.Small>
      </HStack>
    </Pressable>
  );
};

function getPurchaseTariff(purchase: PurchaseMix): string | undefined {
  if ('tariff' in purchase) {
    return purchase.tariff?.companyTariffName;
  }
  return undefined;
}
