import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
// import { FerryCompanyPassengerTicketConfig } from '@naus-code/naus-server-common-types';
import { useCallback } from 'react';
import { RefreshControl } from 'react-native-gesture-handler';

import { useAddFerryPassengerTicketModal } from '~/components/Config/CompanyConfig/Modals/Tickets/Modals';
import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import { FlatList } from '~/components/FlatList/FlatList';
import { ScreenPropPassengerConfigs } from '~/navigation/Navigation.types';
import { translate } from '~/utils/translation.utils';

import { CompanyConfigItemHeader } from '../../CompanyConfig.ItemHeader';
import { companyConfigApi } from '../../CompanyConfigs.api';
// import { PassengerConfig } from './PassengerConfig';

export const PassengerConfigs = ({ id }: { id: string }) => {
  const {
    list: passengerConfigs,
    Search,
    isLoading,
    isFetching,
    refetch,
  } = useSearchState({
    hook: companyConfigApi.useGetFerryCompanyConfigQuery,
    style: {
      height: 47,
    },
    arg: id,
    keyToList: 'config.ticketTypeConfig.passengerConfigs',
  });
  // const { data } = companyConfigApi.useGetFerryCompanyConfigQuery(id);
  const { open: openAddPassengeerModal } = useAddFerryPassengerTicketModal(id);
  const { spacing } = useAppTheme();

  const ItemSeparatorComponent = useCallback(() => <View my="1" />, []);

  const allPassengers = passengerConfigs.map((passengerConfig) => {
    return { ...passengerConfig, id: passengerConfig.code };
  });

  const renderItem = () =>
    // passengerConfig: FerryCompanyPassengerTicketConfig & { id: string },
    {
      return (
        // <PassengerConfig
        //   check={data?.check || ''}
        //   companyId={id}
        //   passengerConfig={passengerConfig}
        //   key={passengerConfig.id}
        // />
        <></>
      );
    };
  return (
    <VStack flex={1}>
      {isNativeMobile ? <CompanyConfigItemHeader id={id} /> : <></>}
      <VStack flex={1} p="2.5" space="2.5">
        <ConfigListSectionTitle>
          <HStack>
            <Text.Small flex={1} color="monochrome-mid">
              {translate('companyConfig.passengerConfigs')}
            </Text.Small>
            <IconButton icon="plus" onPress={openAddPassengeerModal} />
          </HStack>
        </ConfigListSectionTitle>
        {Search}
        <FlatList
          refreshControl={
            <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
          }
          contentContainerStyle={[
            {
              paddingTop: spacing[2.5],
            },

            isNativeMobile && { paddingHorizontal: spacing[2] },
          ]}
          simple
          scrollEnabled
          feedback={translate('companyConfig.emptyPassengerConfigs')}
          isLoading={isLoading}
          data={allPassengers}
          renderItem={renderItem}
          ItemSeparatorComponent={ItemSeparatorComponent}
        />
      </VStack>
    </VStack>
  );
};

export const PassengerConfigsScreen = (props: ScreenPropPassengerConfigs) => {
  return <PassengerConfigs id={props.route.params.id} />;
};
