import { PageContainer } from '~/components/Page/Page.Container';
import { TrafficManagerItem } from './TrafficManager.item';
import { useEffect } from 'react';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { socketProvider } from '~/utils/socketProvider';

export const TrafficManagerScreen = () => {
  return (
    <PageContainer>
      <TrafficManagerLayout />
    </PageContainer>
  );
};

export const TrafficManagerLayout = () => {
  const socket = socketProvider.getSocket();

  useEffect(() => {
    return () => {
      socket.emit('disconnect-traffic-manager');
    };
  }, []);

  return (
    <View style={{ height: '100%' }}>
      <TrafficManagerItem />
    </View>
  );
};
