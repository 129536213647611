import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';

import { ModalScrollView } from '~/components/Modals/Modal.utils';

import { usersApi } from '../../Users.api';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import JSONTree from 'react-native-json-tree';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';

const ModalKey = 'UserInfoFromSocketIdModal';

export const useUserInfoFromSocketIdModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (socketId) => {
      openModal(<UserInfoFromSocketIdModal socketId={socketId} />, {
        key: ModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};

const UserInfoFromSocketIdModal = ({ socketId }: { socketId: string }) => {
  const { closeModal } = useModal();

  return (
    <Modal.Container>
      <Modal.Header
        onPress={() => {
          closeModal(ModalKey);
        }}
        title={'User Info'}
        divider
      />

      <ModalScrollView>
        <UserInfo socketId={socketId} />
      </ModalScrollView>
    </Modal.Container>
  );
};

export const UserInfo = ({ socketId }: { socketId: string }) => {
  const { data, isLoading } = usersApi.useGetUserFromSocketIdQuery(socketId);

  return (
    <DelayRender isLoading={isLoading}>
      {data ? (
        <JSONTree
          data={data as any}
          hideRoot
          theme={{
            scheme: 'google',
            author: 'seth wright (http://sethawright.com)',
            base00: '#1d1f21',
            base01: '#282a2e',
            base02: '#373b41',
            base03: '#969896',
            base04: '#b4b7b4',
            base05: '#c5c8c6',
            base06: '#e0e0e0',
            base07: '#ffffff',
            base08: '#CC342B',
            base09: '#F96A38',
            base0A: '#FBA922',
            base0B: '#198844',
            base0C: '#3971ED',
            base0D: '#3971ED',
            base0E: '#A36AC7',
            base0F: '#3971ED',
          }}
          valueRenderer={(v) => {
            return (
              <View>
                <Text.Small bold color="secondary-mid">
                  {v as any}
                </Text.Small>
              </View>
            );
          }}
          shouldExpandNode={() => true}
        />
      ) : null}
    </DelayRender>
  );
};
