import { ConfigForData, SelectedValueItem } from '@droplet-tech-code/core-common-types';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import {
  TicketCabinDetails,
  TicketPassengerWithHistory,
} from '@naus-code/naus-client-types';
import { ReplaceFirebaseTimestamp } from '@naus-code/naus-server-utils-common-types';

function extractCabinDetails(cabinDetails?: TicketCabinDetails) {
  if (cabinDetails) {
    return cabinDetails;
  }
  return {
    id: undefined,
    capacity: undefined,
    wholeUse: undefined,
    count: undefined,
  };
}

export function generateModifyPassengerTicketInfoManualForm(
  ticket: ReplaceFirebaseTimestamp<TicketPassengerWithHistory>,
  countries: SelectedValueItem[],
): ConfigForData {
  const {
    companyTicketType,
    p_nationality,
    seatNumber,
    loyaltyNumber,
    residentId,
    p_birthPlace,
    p_documentType,
    p_documentNumber,
    p_documentExpirationDate,
    companyTicketNumber,
    cabinDetails,
  } = ticket;

  const {
    id: cabinId,
    capacity: cabinCapacity,
    wholeUse: cabinWholeUse,
    count: cabinCount,
  } = extractCabinDetails(cabinDetails);

  let cabinConfig = [] as ConfigForData;

  if (cabinDetails) {
    cabinConfig = [
      {
        items: [
          {
            id: 'cabinId',
            type: 'text',
            label: 'Cabin Id',
            optional: !cabinId,
            initialValue: cabinId,
          },
          {
            id: 'cabinCapacity',
            type: 'number',
            label: 'Cabin capacity',
            optional: !cabinCapacity,
            initialValue: cabinCapacity,
          },
          {
            id: 'cabinWholeUse',
            type: 'toggle',
            label: 'Whole cabin use',
            optional: cabinWholeUse === undefined,
            initialValue: cabinWholeUse,
          },
          {
            id: 'cabinCount',
            type: 'number',
            label: 'Cabin count',
            optional: !cabinCount,
            initialValue: cabinCount,
          },
        ],
      },
    ];
  }

  return [
    {
      items: [
        {
          id: 'companyTicketNumber',
          type: 'text',
          label: 'Company Ticket Num',
          optional: !companyTicketNumber,
          initialValue: companyTicketNumber,
        },
        {
          id: 'loyaltyNumber',
          type: 'text',
          label: 'Loyalty',
          optional: !loyaltyNumber,
          initialValue: loyaltyNumber,
        },
        {
          id: 'companyTicketType',
          type: 'text',
          label: 'Ticket Type',
          optional: !companyTicketType,
          initialValue: companyTicketType,
        },
      ],
    },
    {
      items: [
        {
          id: 'seatNumber',
          type: 'text',
          label: 'Seat',
          optional: !seatNumber,
          initialValue: seatNumber,
        },
        {
          id: 'p_nationality',
          type: 'single-select',
          list: countries,
          label: 'Nat',
          optional: !p_nationality,
          initialValue: p_nationality,
        },
        {
          id: 'residentId',
          type: 'text',
          label: 'Resident Id',
          optional: !residentId,
          initialValue: residentId,
        },
      ],
    },
    {
      items: [
        {
          id: 'p_birthPlace',
          type: 'text',
          label: 'Birth Place',
          optional: !p_birthPlace,
          initialValue: p_birthPlace,
        },
        {
          id: 'p_documentType',
          type: 'text',
          label: 'Document Type',
          optional: !p_documentType,
          initialValue: p_documentType,
        },
        {
          id: 'p_documentNumber',
          type: 'text',
          label: 'Document Number',
          optional: !p_documentNumber,
          initialValue: p_documentNumber,
        },
        {
          id: 'p_documentExpirationDate',
          type: 'date',
          label: 'Document Exp.',
          format: 'YYYY-MM-DD',
          optional: !p_documentExpirationDate,
          initialValue: dayjs(p_documentExpirationDate).format('YYYY-MM-DD'),
        },
      ],
    },
    ...cabinConfig,
  ];
}
