import { PageContainer } from '~/components/Page/Page.Container';
import { GetAdminUserWithActivityStatusResponse, usersApi } from '../../Users.api';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { TeamOverViewHeader } from './SupportTeamOverview.Header';
import { Table } from '~/components/Table/Table';
import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { translate } from '~/utils/translation.utils';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { AppText } from '~/components/Text';
import { SocketInfo } from './SupportTeamOverview.SocketInfo';
import { useIsSystemUser } from '~/hooks/auth.hook';

export const SupportTeamOverviewScreen = () => {
  return (
    <PageContainer>
      <SupportTeamOverviewLayout />
    </PageContainer>
  );
};

export const SupportTeamOverviewLayout = () => {
  const { data, isLoading, refetch } =
    usersApi.useGetCustomerSupportTeamLeadManageesQuery(undefined, {
      pollingInterval: 10000,
    });

  const isSystem = useIsSystemUser();

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (!data) {
    return null;
  }

  const { managees } = data;

  return (
    <VStack>
      <TeamOverViewHeader managees={managees} />
      <VStack p="2.5" space="2.5">
        <TeamOverviewTable refetch={refetch} managees={managees} />
        {isSystem && <SocketInfo />}
      </VStack>
    </VStack>
  );
};

const TeamOverviewTable = ({
  refetch,
  managees,
}: {
  refetch: () => void;
  managees: GetAdminUserWithActivityStatusResponse[];
}) => {
  const tableHeaders = [
    'name',
    'status',
    'New SR',
    'Open SR',
    'Pending SR',
    'Closed SR',
    'Daily',
  ];

  const tableData = managees.map((managee) => {
    const adminUser = managee.adminUser;
    return [
      adminUser.name + ' ' + adminUser.lastName,
      managee.isOnline ? 'ON' : 'OFF',
      adminUser.pendingSupportRequests,
      adminUser.openSupportRequests,
      adminUser.pausedSupportRequests,
      adminUser.closedSupportRequests,
      adminUser.dailySupportRequests,
    ];
  });
  const onlineUsers = managees.filter((managee) => managee.isOnline).length;
  const onlineStatuses = managees.map((managee) => managee.isOnline);
  return (
    <VStack>
      <CardWithHeader
        headerText={`${translate('supportTeam.usersCurrentlyOnline')} (${onlineUsers})`}
        iconRightType="refresh"
        iconRightOnPress={refetch}
        w={700}
      >
        <HStack>
          <StatusLightCol onlineStatuses={onlineStatuses} />
          <Table
            data={tableData}
            headers={tableHeaders}
            cellAlign="flex-start"
            rowHeight={16}
          />
        </HStack>
      </CardWithHeader>
    </VStack>
  );
};

const StatusLightCol = ({ onlineStatuses }: { onlineStatuses: boolean[] }) => {
  return (
    <VStack p="2.5" key={'color-column'}>
      <VStack py="1" space="2.5">
        <View justify="center" align="center" h={16}>
          <AppText.ExtraSmall align="center" color="monochrome-dark">
            {''}
          </AppText.ExtraSmall>
        </View>
        {onlineStatuses.map((status, index) => {
          const color = status ? 'success-light' : 'monochrome';
          return (
            <View br={20} align="center" justify="center" key={index} h={16}>
              <View bg={color} br={20} key={index} style={{ height: 6, width: 6 }} />
            </View>
          );
        })}
      </VStack>
    </VStack>
  );
};
