import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  GetSupportRequestResponse,
  SupportRequestAddSupportNoteRequest,
  SupportRequestCancelPendingPaymentRequest,
  SupportRequestChangeAdminUserRequest,
  SupportRequestChangeLangRequest,
  SupportRequestChangeSelectionReferencesRequest,
  SupportRequestChangeStatusRequest,
  SupportRequestChangeSupportTypeRequest,
  SupportRequestCreateChargeRequest,
  SupportRequestCreatePendingRefundRequest,
  SupportRequestDeleteReplyRequest,
  SupportRequestEditReplyRequest,
  SupportRequestFillDynamicTemplateAnswersRequest,
  SupportRequestFillDynamicTemplateAnswersResponse,
  SupportRequestGetTemplateAnswersResponse,
  SupportRequestSendReminderRequest,
  SupportRequestSendReplyRequest,
  SupportUpdateCheck,
} from '@naus-code/naus-admin-types';
import { ImageUploadResponse } from '@naus-code/naus-client-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'support-request/actions';
export const supportRequestActionsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    assignAdminUser: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestChangeAdminUserRequest
    >({
      query: (data) => ({
        url: `${base_url}/assign-admin-user/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getSupportRequest',
        // 'User_Item',
        'getSupportRequestPending',
        'assignAdminUserFormConfig',
      ],
    }),

    assignAdminUserSelf: builder.mutation<
      GetSupportRequestResponse,
      Omit<SupportRequestChangeAdminUserRequest, 'adminUserId'>
    >({
      query: (data) => ({
        url: `${base_url}/assign-admin-user-self/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getSupportRequest',
        // 'User_Item',
        'getSupportRequestPending',
        'assignAdminUserFormConfig',
      ],
    }),
    unassignAdminUserSelf: builder.mutation<
      GetSupportRequestResponse,
      Omit<SupportRequestChangeAdminUserRequest, 'adminUserId'>
    >({
      query: (data) => ({
        url: `${base_url}/unassign-admin-user-self/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getSupportRequest',
        // 'User_Item',
        'getSupportRequestPending',
        'assignAdminUserFormConfig',
      ],
    }),

    unassignAdminUser: builder.mutation<
      GetSupportRequestResponse,
      Omit<SupportRequestChangeAdminUserRequest, 'adminUserId'>
    >({
      query: (data) => ({
        url: `${base_url}/unassign-admin-user/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getSupportRequest',
        // 'User_Item',
        'getSupportRequestPending',
        'assignAdminUserFormConfig',
      ],
    }),

    assignAdminUserFormConfig: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/form-config/assign-admin-user/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'assignAdminUserFormConfig'],
    }),
    sendCustomerReminder: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestSendReminderRequest
    >({
      query: (data) => ({
        url: `${base_url}/send-customer-reminder/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['sendCustomerReminder', 'getSupportRequest'],
    }),
    createPaymentChargeFormConfig: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/form-config/create-payment/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'createPaymentChargeFormConfig', 'getSupportRequest'],
    }),

    createPaymentRefundFormConfig: builder.query<ConfigForData, string>({
      query: (id) => ({
        url: `${base_url}/form-config/create-refund/v1/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'createPaymentRefundFormConfig', 'getSupportRequest'],
    }),

    createPaymentCharge: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestCreateChargeRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-payment-charge/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest'],
    }),

    createPendingPaymentCharge: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestCreateChargeRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-pending-payment/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest'],
    }),

    createPendingPaymentRefund: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestCreatePendingRefundRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-pending-refund/v1/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest', 'getSupportRequestPending'],
    }),

    approvePendingRefund: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestCancelPendingPaymentRequest
    >({
      query: (data) => ({
        url: `${base_url}/approve-payment-refund/v1/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'createPaymentRefundFormConfig',
        'getSupportRequest',
        'getBooking',
        'getSupportRequestPending',
      ],
    }),

    cancelPendingPayment: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestCancelPendingPaymentRequest
    >({
      query: (data) => ({
        url: `${base_url}/cancel-pending-payment-charge/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'cancelPendingPayment',
        'getSupportRequest',
        'getSupportRequestPending',
      ],
    }),

    cancelPendingRefund: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestCancelPendingPaymentRequest
    >({
      query: (data) => ({
        url: `${base_url}/cancel-pending-refund/v1/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest', 'getBooking', 'getSupportRequestPending'],
    }),

    changeSupportStatus: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestChangeStatusRequest
    >({
      query: (data) => ({
        url: `${base_url}/change-support-status/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'changeSupportStatus',
        'getSupportRequest',
        // "getSupportRequestClosed",
        // "getSupportRequestPaused",
        'getSupportRequestPending',
      ],
    }),

    togglePendingOperatorResponse: builder.mutation<
      GetSupportRequestResponse,
      SupportUpdateCheck
    >({
      query: (data) => ({
        url: `${base_url}/toggle-pending-operator-response/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest', 'getSupportRequestPending'],
    }),

    // //--------------Notes--------------//
    addSupportNote: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestAddSupportNoteRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-support-note/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['addSupportNote', 'getSupportRequest'],
    }),

    //---------------Template Answers------------------//

    getTemplateAnswers: builder.query<SupportRequestGetTemplateAnswersResponse, void>({
      query: () => ({
        url: `${base_url}/get-template-answers/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getTemplateAnswers'],
    }),
    fillDynamicTemplateAnswer: builder.mutation<
      SupportRequestFillDynamicTemplateAnswersResponse,
      SupportRequestFillDynamicTemplateAnswersRequest
    >({
      query: (data) => ({
        url: `${base_url}/fill-template/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['fillDynamicTemplateAnswer'],
    }),

    // @Post('/img-upload/reply/v1/')
    // replyToSupportUploadImage(@RequestDeco() body: Request): Promise<ImageUploadResponse> {
    //     return this._supportRequestService.getReplyImageToken(body);
    // }

    replyToSupportUploadImage: builder.mutation<ImageUploadResponse, FormData>({
      query: (data) => ({
        url: `${base_url}/img-upload/reply/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest'],
    }),

    sendReply: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestSendReplyRequest
    >({
      query: (data) => ({
        url: `${base_url}/send-reply/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest'],
    }),

    // @Get('/form-config/send-reply/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // sendReplyFormConfig(): Promise<ConfigForData> {
    //     return this._supportRequestService.sendReplyFormConfig();
    // }

    sendReplyFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/send-reply/`,
        method: 'GET',
      }),
      providesTags: ['*', 'sendReplyFormConfig'],
    }),
    editReplyFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/edit-reply/`,
        method: 'GET',
      }),
      providesTags: ['*', 'editReplyFormConfig'],
    }),
    editReply: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestEditReplyRequest
    >({
      query: (data) => ({
        url: `${base_url}/edit-reply/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest'],
    }),
    deleteReply: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestDeleteReplyRequest
    >({
      query: (data) => ({
        url: `${base_url}/delete-reply/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest'],
    }),
    // @Post('/change-support-language/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //     changeSupportLang(
    //         @Body() body: SupportRequestChangeLangRequest,
    //     ): Promise<GetSupportRequestResponse> {
    //         return this._supportRequestService.changeSupportLang(body);
    //     }
    changeSupportLang: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestChangeLangRequest
    >({
      query: (data) => ({
        url: `${base_url}/change-support-language/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getTemplateAnswers', 'getSupportRequest'],
    }),
    // @Post('/change-selection-references/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //     changeSelectionReferences(
    //         @Body() body: SupportRequestChangeSelectionReferencesRequest,
    //     ): Promise<GetSupportRequestResponse> {
    //         return this._supportRequestService.changeSelectionReferences(body);
    //     }

    changeSelectionReferences: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestChangeSelectionReferencesRequest
    >({
      query: (data) => ({
        url: `${base_url}/change-selection-references/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest'],
    }),
    // @Get('/form-config/change-support-type/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // changeSupportTypeFormConfig(): Promise<ConfigForData> {
    //     return this._supportRequestService.changeSupportTypeConfig();
    // }

    // @Post('/change-support-type/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // changeSupportType(
    //     @Body() body: SupportRequestChangeSupportTypeRequest,
    // ): Promise<GetSupportRequestResponse> {
    //     return this._supportRequestService.changeSupportType(body);
    // }

    getChangeSupportTypeFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/change-support-type/`,
        method: 'GET',
      }),
      providesTags: ['*', 'changeSupportTypeFormConfig'],
    }),

    changeSupportType: builder.mutation<
      GetSupportRequestResponse,
      SupportRequestChangeSupportTypeRequest
    >({
      query: (data) => ({
        url: `${base_url}/change-support-type/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getSupportRequest',
        'changeSupportTypeFormConfig',
        'getSupportRequestClosed',
        'getSupportRequestPaused',
        'getSupportRequestPending',
      ],
    }),

    toggleAutoUpdateAgent: builder.mutation<
      GetSupportRequestResponse,
      SupportUpdateCheck
    >({
      query: (data) => ({
        url: `${base_url}/toggle-auto-translate-agent`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest'],
    }),

    toggleAutoUpdateCustomer: builder.mutation<
      GetSupportRequestResponse,
      SupportUpdateCheck
    >({
      query: (data) => ({
        url: `${base_url}/toggle-auto-translate-customer`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportRequest'],
    }),
  }),
});
