import {
  isWeb,
  notEmpty,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { Globals } from '~/components/Globals/Globals';
import { CompanyConfigItemScreen } from '~/screens/Configs/Company/CompanyConfigItem.Screen';
import { CompanyConfigScreen } from '~/screens/Configs/Company/CompanyConfigs.Screen';
import { CompanyOperationsConfigScreen } from '~/screens/Configs/Company/SidePanels/CompanyOperations/CompanyOperations';
import { CompanyOperatorsConfigScreen } from '~/screens/Configs/Company/SidePanels/CompanyOperators/CompanyOperators';
import { DiscountsConfigScreen } from '~/screens/Configs/Company/SidePanels/Discounts/DiscountsConfig';
import { ExtraConfigsScreen } from '~/screens/Configs/Company/SidePanels/ExtraConfigs/ExtraConfigs';
import { PassengerConfigsScreen } from '~/screens/Configs/Company/SidePanels/PassengerConfigs/PassengerConfigs';
import { VehicleConfigsScreen } from '~/screens/Configs/Company/SidePanels/VehicleConfigs/VehicleConfigs';
// import { CompanyDataScreen } from '~/screens/Configs/CompanyData/CompanyData.Screen';
// import { MissingDiscountConfigsScreen } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Discounts/MissingDiscountConfigs.Screen';
// import { MissingExtraConfigsScreen } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Extras/MissingExtraConfigs.Screen';
// import { MissingLocationConfigsScreen } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Locations/MissingLocationConfigs.Screen';
// import { MissingPassengerConfigsScreen } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Passengers/MissingPassengerConfigs.Screen';
// import { MissingVehicleConfigsScreen } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Vehicles/MissingVehicleConfigs.Screen';
// import { MissingVesselConfigsScreen } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Vessels/MissingVesselConfigs.Screen';
import { SupportConfigOfficeScreen } from '~/screens/Configs/Support/Office/SupportConfigOfficeScreen';
import { SupportConfigOperationScreen } from '~/screens/Configs/Support/Operation/supportConfigOperationScreen';
import { SupportConfigScreen } from '~/screens/Configs/Support/SupportConfig.Screen';
import { SupportConfigTemplateScreen } from '~/screens/Configs/Support/Template/SupportConfigTemplateScreen';
import { SystemConfigScreen } from '~/screens/Configs/System/SystemConfig.Screen';
import { CreditLineScreen } from '~/screens/Finance/CreditLine/CreditLine.Screen';
import { TransactionsScreen } from '~/screens/Finance/Transactions/Transactions.Screen';
import { AreaLocationItemScreen } from '~/screens/Locations/AreaLocations/AreaLocationItem.Screen';
import { AreaLocationsScreen } from '~/screens/Locations/AreaLocations/AreaLocations.Screen';
import { PortLocationItemScreen } from '~/screens/Locations/PortLocations/PortLocationItem.Screen';
import { PortLocationsScreen } from '~/screens/Locations/PortLocations/PortLocations.Screen';
import { ActiveNoticesScreen } from '~/screens/Notices/ActiveNotices/ActiveNotices.Screen';
import { NoticeItemScreen } from '~/screens/Notices/Notice.Item';
import { PendingNoticesScreen } from '~/screens/Notices/PendingNotices/PendingNotices.Screen';
import { ScheduledChangeScreen } from '~/screens/ScheduledChanges/ChangeList/ScheduledChanges.Screen';
import { CreateScheduledChangeScreen } from '~/screens/ScheduledChanges/Create/ScheduledChanges.Create.Screen';
import { BookingItemScreen } from '~/screens/Support/Bookings/BookingItem';
import { BookingsByCustomerIdScreen } from '~/screens/Support/Bookings/BookingsByCustomerId/BookingsByCustomerId.Screen';
import { BookingsByEmailScreen } from '~/screens/Support/Bookings/BookingsByEmail/BookingsByEmail.Screen';
import { BookingsByReferenceScreen } from '~/screens/Support/Bookings/BookingsByRef/BookingsByRef.Screen';
import { BookingsRecentScreen } from '~/screens/Support/Bookings/BookingsRecent/BookingsRecent.Screen';
import { PurchaseItemScreenNativeComponent } from '~/screens/Support/Purchases/PurchaseItem';
import { PurchasesByCRCScreen } from '~/screens/Support/Purchases/PurchasesByCompanyCode/PurchasesByCRC.Screen';
import { PurchasesByCTNScreen } from '~/screens/Support/Purchases/PurchasesByCompanyTicketNumber/PurchasesByCTN.Screen';
import { PurchasesByCustomerIdScreen } from '~/screens/Support/Purchases/PurchasesByCustomerId/PurchasesByCustomerId.Screen';
import { PurchasesByDateScreen } from '~/screens/Support/Purchases/PurchasesByDate/PurchasesByDate.Screen';
import { PurchasesByEmailScreen } from '~/screens/Support/Purchases/PurchasesByEmail/PurchasesByEmail.Screen';
import { PurchasesByPRCScreen } from '~/screens/Support/Purchases/PurchasesByProvider/PurchasesByPRC.Screen';
import { PurchasesUpcomingScreen } from '~/screens/Support/Purchases/PurchasesUpcoming/PurchasesUpcoming.Screen';
import { ReservationFromProviderScreen } from '~/screens/Support/Purchases/PurchaseTools/ReservationFromProvider';
import { ClosedSupportRequestsScreen } from '~/screens/Support/Requests/Closed/SupportRequestsClosed.Screen';
import { CustomerSupportRequestsScreen } from '~/screens/Support/Requests/Customer/SupportRequestsCustomer.Screen';
import { PausedSupportRequestsScreen } from '~/screens/Support/Requests/Paused/SupportRequestsPaused.Screen';
import { PendingSupportRequestsScreen } from '~/screens/Support/Requests/Pending/SupportRequestsPending.Screen';
import { PurchaseSupportRequestsScreen } from '~/screens/Support/Requests/Purchase/SupportRequestsPurchase.Screen';
import { SupportRequestByIdScreen } from '~/screens/Support/Requests/SupportRequestById/SupportRequestById.Screen';
import { SupportRequestItemScreen } from '~/screens/Support/Requests/SupportRequestItem';
import { AdminUserItemScreen } from '~/screens/Users/Admin/AdminUser.ItemScreen';
import { AdminUsersScreen } from '~/screens/Users/Admin/AdminUsers.Screen';
import { VesselItemScreen } from '~/screens/Vessels/Item/Vessel.ItemScreen';
import { VesselsScreen } from '~/screens/Vessels/Vessels.Screen';
import { CreateVoucherScreen } from '~/screens/Vouchers/CreateVoucher/CreateVoucherScreen';
import { VoucherItemScreen } from '~/screens/Vouchers/Voucher.Item';
import { VoucherByAdminRefScreen } from '~/screens/Vouchers/VoucherByAdminRef/VoucherByAdminRefScreen';
import { VoucherByEmailScreen } from '~/screens/Vouchers/VoucherByEmail/VoucherByEmailScreen';
import { VoucherByTinyIdScreen } from '~/screens/Vouchers/VoucherByTinyId/VoucherByTinyIdScreen';

import { EventsScreen } from '../screens/Events/Events.Screen';
import { translate } from '../utils/translation.utils';
import { ScreenDictionary, ScreenList } from './Navigation.types';
import { MetaSearchJobsScreen } from '~/screens/DataManagement/SearchMeta/MetaSearchJobs.screen';
import { DataManagementPanelScreen } from '~/screens/DataManagement/DataManagementPanel/DataManagementPanel.screen';
import { SupportTeamOverviewScreen } from '~/screens/Users/SupportTeam/SupportTeamOverview/SupportTeamOverview.Screen';
import { TrafficManagerScreen } from '~/screens/DataManagement/TrafficManager/TrafficManager.screen';

const getScreenDictionary = () =>
  (
    [
      // {
      //   name: "Testing",
      //   label: "Testing",
      //   component: TestScreen,
      //   icon: "todo-list",
      // },
      {
        name: 'Support',
        icon: 'floater',
        label: 'Support',
        access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
        children: [
          {
            groupName: 'Requests',
            groupLabel: translate('groups.requests'),
            access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
            list: [
              {
                name: 'PendingSupportRequests',
                label: translate('screens.pendingRequests'),
                component: PendingSupportRequestsScreen,
                icon: 'warning',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'ClosedSupportRequests',
                label: translate('screens.closedRequests'),
                component: ClosedSupportRequestsScreen,
                icon: 'support-closed',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'PausedSupportRequests',
                label: translate('screens.pausedRequests'),
                component: PausedSupportRequestsScreen,
                icon: 'circle-pause',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'SupportRequestById',
                label: translate('screens.supportRequestById'),
                component: SupportRequestByIdScreen,
                icon: 'search',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'SupportRequest-Item',
                label: translate('screens.supportRequestItem'),
                component: SupportRequestItemScreen,
                icon: 'todo-list',
                nonMenu: true,
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
            ],
          },
          {
            groupName: 'Bookings',
            groupLabel: translate('groups.bookings'),
            access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
            list: [
              {
                name: 'BookingsByEmail',
                label: translate('screens.bookingsByEmail'),
                component: BookingsByEmailScreen,
                icon: 'receipt-email',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'BookingsByCustomerId',
                label: translate('screens.bookingsByCustomerId'),
                component: BookingsByCustomerIdScreen,
                icon: 'receipt-email',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'BookingsByRef',
                label: translate('screens.bookingsByRef'),
                component: BookingsByReferenceScreen,
                icon: 'receipt-email',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'BookingsRecent',
                label: translate('screens.bookingsRecent'),
                component: BookingsRecentScreen,
                icon: 'receipt-email',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'Booking-Item',
                label: translate('screens.bookingItem'),
                component: BookingItemScreen,
                icon: 'receipt-email',
                nonMenu: true,
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
            ],
          },
          {
            groupName: 'Purchases',
            groupLabel: translate('groups.purchases'),
            access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
            list: [
              {
                name: 'PurchasesByEmail',
                label: translate('screens.purchasesByEmail'),
                component: PurchasesByEmailScreen,
                icon: 'ticket-email',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'PurchasesByCustomerId',
                label: translate('screens.purchasesByCustomerId'),
                component: PurchasesByCustomerIdScreen,
                icon: 'user',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'PurchasesByCRC',
                label: translate('screens.purchasesByCRC'),
                component: PurchasesByCRCScreen,
                icon: 'small-office',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'PurchasesByPRC',
                label: translate('screens.purchasesByPRC'),
                component: PurchasesByPRCScreen,
                icon: 'office',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'PurchasesByCTN',
                label: translate('screens.purchasesByCTN'),
                component: PurchasesByCTNScreen,
                icon: 'ticket-number',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'PurchasesByDate',
                label: translate('screens.purchasesByDate'),
                component: PurchasesByDateScreen,
                icon: 'calendar',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'PurchasesUpcoming',
                label: translate('screens.purchasesUpcoming'),
                component: PurchasesUpcomingScreen,
                icon: 'ticket-number',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'Purchase-Item',
                label: '',
                component: PurchaseItemScreenNativeComponent,
                icon: 'ticket-number',
                nonMenu: true,
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
            ],
          },
          {
            groupName: 'PurchaseTools',
            groupLabel: translate('groups.purchaseTools'),
            access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
            list: [
              {
                name: 'ReservationFromProvider',
                label: translate('screens.ReservationFromProvider'),
                component: ReservationFromProviderScreen,
                icon: 'a-la-carte',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
              {
                name: 'PurchaseSupportRequests',
                label: translate('screens.purchaseSupportRequests'),
                component: PurchaseSupportRequestsScreen,
                icon: 'customer-support',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
            ],
          },
          {
            groupName: 'Customer',
            groupLabel: translate('screens.customer'),
            access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
            list: [
              {
                name: 'CustomerSupportRequests',
                label: translate('screens.customerRequests'),
                component: CustomerSupportRequestsScreen,
                icon: 'customer-support',
                access: ['ADMIN', 'CUSTOMER_SUPPORT', 'CUSTOMER_SUPPORT_LEAD'],
              },
            ],
          },
        ],
      },
      {
        name: 'SupportTeam',
        icon: 'support-agent',
        access: ['CUSTOMER_SUPPORT_LEAD', 'ADMIN'],
        children: [
          {
            groupName: 'SupportTeam',
            groupLabel: translate('groups.supportTeam'),
            access: ['CUSTOMER_SUPPORT_LEAD', 'ADMIN'],
            list: [
              {
                name: 'SupportTeamOverview',
                label: translate('screens.teamOverview'),
                component: SupportTeamOverviewScreen,
                icon: 'support-agent',
                singleTab: true,
                access: ['CUSTOMER_SUPPORT_LEAD', 'ADMIN'],
              },
            ],
          },
        ],
      },
      {
        name: 'Finance',
        label: 'Finance',
        icon: 'bank-refund-receive',
        access: ['FINANCE'],
        children: [
          {
            groupName: 'Operators',
            groupLabel: translate('groups.operators'),
            access: ['FINANCE'],
            list: [
              {
                name: 'AllCreditLines',
                label: translate('operators.allCreditLines'),
                component: CreditLineScreen,
                icon: 'euro',
                singleTab: true,
                access: ['FINANCE'],
              },

              ...[
                ...(isWeb
                  ? [
                      {
                        name: 'Transactions',
                        component: TransactionsScreen,
                        icon: 'transactions',
                        label: translate('transactions.transactions'),
                        singleTab: true,
                        access: ['FINANCE'],
                      },
                    ]
                  : []),
              ],
            ],
          },
          {
            groupName: 'Bookings',
            groupLabel: translate('groups.bookings'),
            access: ['FINANCE'],
            list: [
              {
                name: 'BookingsByRefFinance',
                label: translate('screens.bookingsByRef'),
                component: BookingsByReferenceScreen,
                icon: 'receipt-email',
                access: ['FINANCE'],
              },
              {
                name: 'BookingsByEmailFinance',
                label: translate('screens.bookingsByEmail'),
                component: BookingsByReferenceScreen,
                icon: 'receipt-email',
                access: ['FINANCE'],
              },
              {
                name: 'BookingsByCustomerIdFinance',
                label: translate('screens.bookingsByCustomerId'),
                component: BookingsByReferenceScreen,
                icon: 'receipt-email',
                access: ['FINANCE'],
              },
            ],
          },
          {
            groupName: 'Purchases',
            groupLabel: translate('groups.purchases'),
            access: ['FINANCE'],
            list: [
              {
                name: 'PurchasesByEmailFinance',
                label: translate('screens.purchasesByEmail'),
                component: PurchasesByEmailScreen,
                icon: 'ticket-email',
                access: ['FINANCE'],
              },
              {
                name: 'PurchasesByCustomerIdFinance',
                label: translate('screens.purchasesByCustomerId'),
                component: PurchasesByCustomerIdScreen,
                icon: 'user',
                access: ['FINANCE'],
              },
            ],
          },
          {
            groupName: 'Requests',
            groupLabel: translate('groups.requests'),
            access: ['FINANCE'],
            list: [
              {
                name: 'SupportRequestByIdFinance',
                label: translate('screens.supportRequestById'),
                component: SupportRequestByIdScreen,
                icon: 'search',
                access: ['FINANCE'],
              },
              {
                name: 'SupportRequest-ItemFinance',
                label: translate('screens.supportRequestItem'),
                component: SupportRequestItemScreen,
                icon: 'todo-list',
                nonMenu: true,
                access: ['FINANCE'],
              },
            ],
          },
        ],
      },
      isWeb
        ? {
            name: 'ScheduledChanges',
            label: 'Scheduled changes',
            icon: 'calendar-spanner',
            rules: ['accessScheduledChanges'],
            access: ['ADMIN'],
            children: [
              {
                groupName: 'ScheduledChanges',
                groupLabel: translate('groups.scheduledChanges'),
                rules: ['accessScheduledChanges'],
                access: ['ADMIN'],
                list: [
                  {
                    name: 'CreateScheduledChanges',
                    label: translate('schedulesChanges.create'),
                    component: CreateScheduledChangeScreen,
                    icon: 'calendar-arrow-in',
                    rules: ['accessScheduledChanges'],
                    access: ['ADMIN'],
                  },
                  {
                    name: 'ScheduledChanges',
                    label: translate('schedulesChanges.pending'),
                    component: ScheduledChangeScreen,
                    icon: 'clock',
                    rules: ['accessScheduledChanges'],
                    access: ['ADMIN'],
                  },
                  // {
                  //   name: "CompletedScheduledChanges",
                  //   label: translate("schedulesChanges.completed"),
                  //   component: CompletedScheduledChangeScreen,
                  //   icon: "check-circle",
                  // },
                ],
              },
            ],
          }
        : undefined,
      // isWeb
      //   ? {
      //       name: 'MissingConfigs',
      //       label: 'Data configs',
      //       icon: 'data-gear',
      //       access: ['ADMIN'],
      //       children: [
      //         {
      //           groupName: 'MissingConfigs',
      //           groupLabel: translate('groups.missingConfigs'),
      //           access: ['ADMIN'],
      //           list: [
      //             {
      //               name: 'MissingVesselConfigs',
      //               label: translate('screens.missingVesselConfigs'),
      //               component: MissingVesselConfigsScreen,
      //               icon: 'vessel-cog',
      //               access: ['ADMIN'],
      //             },
      //             {
      //               name: 'MissingPassengerConfigs',
      //               label: translate('screens.missingPassengerConfigs'),
      //               component: MissingPassengerConfigsScreen,
      //               icon: 'user-cog',
      //               access: ['ADMIN'],
      //             },
      //             {
      //               name: 'MissingVehicleConfigs',
      //               label: translate('screens.missingVehicleConfigs'),
      //               component: MissingVehicleConfigsScreen,
      //               icon: 'car-cog',
      //               access: ['ADMIN'],
      //             },
      //             {
      //               name: 'MissingDiscountConfigs',
      //               label: translate('screens.missingDiscountConfigs'),
      //               component: MissingDiscountConfigsScreen,
      //               icon: 'office-cog',
      //               access: ['ADMIN'],
      //             },
      //             {
      //               name: 'MissingExtraConfigs',
      //               label: translate('screens.missingExtraConfigs'),
      //               component: MissingExtraConfigsScreen,
      //               icon: 'extra-cog',
      //               access: ['ADMIN'],
      //             },
      //             {
      //               name: 'MissingLocationConfigs',
      //               label: translate('screens.missingLocationConfigs'),
      //               component: MissingLocationConfigsScreen,
      //               icon: 'location-pin-cog',
      //               access: ['ADMIN'],
      //             },
      //           ],
      //         },
      //       ],
      //     }
      //   : undefined,

      {
        name: 'System Settings',
        icon: 'setting-gear',
        access: ['ADMIN'],
        children: [
          {
            groupName: 'Configs',
            groupLabel: translate('groups.configs'),
            access: ['ADMIN'],
            list: [
              ...[
                ...(isWeb
                  ? [
                      {
                        name: 'SystemConfig',
                        label: translate('screens.systemConfig'),
                        component: SystemConfigScreen,
                        icon: 'setting-gear',
                        access: ['ADMIN'],
                      },
                    ]
                  : []),
              ],
              ...[
                ...(isWeb
                  ? [
                      {
                        name: 'SupportConfig',
                        label: translate('screens.supportConfig'),
                        component: SupportConfigScreen,
                        icon: 'user-support',
                        access: ['ADMIN'],
                      },
                    ]
                  : []),
              ],
              {
                name: 'SupportConfigOffice',
                label: translate('screens.officeConfig'),
                component: SupportConfigOfficeScreen,
                icon: 'office-cog',
                nonMenu: true,
                access: ['ADMIN'],
              },
              {
                name: 'SupportConfigOperation',
                label: translate('screens.operationConfig'),
                component: SupportConfigOperationScreen,
                icon: 'small-office',
                nonMenu: true,
                access: ['ADMIN'],
              },
              {
                name: 'SupportConfigTemplate',
                label: translate('screens.templateConfig'),
                component: SupportConfigTemplateScreen,
                icon: 'support-bubble',
                nonMenu: true,
                access: ['ADMIN'],
              },
              {
                name: 'CompanyConfig',
                label: translate('screens.companyConfig'),
                component: CompanyConfigScreen,
                icon: 'office',
                access: ['ADMIN'],
              },
              {
                name: 'CompanyConfig-Item',
                label: translate('screens.companyConfigItem'),
                component: CompanyConfigItemScreen,
                icon: 'todo-list',
                nonMenu: true,
                access: ['ADMIN'],
              },
              {
                name: 'CompanyOperatorsConfig',
                label: translate('screens.companyOperatorsConfig'),
                component: CompanyOperatorsConfigScreen,
                icon: 'office',
                nonMenu: true,
                access: ['ADMIN'],
              },
              {
                name: 'CompanyOperationsConfig',
                label: translate('screens.companyOperationsConfig'),
                component: CompanyOperationsConfigScreen,
                icon: 'office',
                nonMenu: true,
                access: ['ADMIN'],
              },
              {
                name: 'DiscountsConfig',
                label: translate('screens.discountsConfig'),
                component: DiscountsConfigScreen,
                icon: 'ticket-offers',
                nonMenu: true,
                access: ['ADMIN'],
              },
              {
                name: 'PassengerConfigs',
                label: translate('screens.passengerConfigs'),
                component: PassengerConfigsScreen,
                icon: 'user-cog',
                nonMenu: true,
                access: ['ADMIN'],
              },
              {
                name: 'VehicleConfigs',
                label: translate('screens.vehicleConfigs'),
                component: VehicleConfigsScreen,
                icon: 'car-cog',
                nonMenu: true,
                access: ['ADMIN'],
              },
              {
                name: 'ExtraConfigs',
                label: translate('screens.extraConfigs'),
                component: ExtraConfigsScreen,
                icon: 'extra-cog',
                nonMenu: true,
                access: ['ADMIN'],
              },
            ],
          },
        ],
      },

      {
        name: 'Events',
        component: EventsScreen,
        icon: 'todo-list',
        label: translate('events.events'),
        access: ['ADMIN'],
      },
      {
        name: 'Users',
        label: 'Users',
        icon: 'user',
        access: ['ADMIN'],
        children: [
          {
            groupName: 'Admin Users',
            groupLabel: translate('groups.adminUsers'),
            access: ['ADMIN'],
            list: [
              {
                name: 'AdminUsers',
                label: translate('screens.users'),
                component: AdminUsersScreen,
                icon: 'user-cog',
                access: ['ADMIN'],
              },
              {
                name: 'AdminUser-Item',
                label: translate('screens.userItem'),
                platform: 'native',
                nonMenu: true,
                component: AdminUserItemScreen,
                access: ['ADMIN'],
              },
            ],
          },
        ],
      },
      {
        name: 'Vouchers',
        label: 'Vouchers',
        icon: 'voucher',
        access: ['ADMIN', 'FINANCE'],
        children: [
          {
            groupName: 'VoucherOperations',
            groupLabel: translate('groups.vouchersOperations'),
            access: ['ADMIN'],
            list: [
              {
                name: 'CreateVoucher',
                label: translate('screens.createVoucher'),
                component: CreateVoucherScreen,
                icon: 'voucher-full',
                access: ['ADMIN'],
              },
            ],
          },
          {
            groupName: 'VoucherLists',
            groupLabel: translate('groups.voucherLists'),
            access: ['ADMIN', 'FINANCE'],
            list: [
              {
                name: 'VouchersByAdminRef',
                label: translate('screens.vouchersByAdminRef'),
                component: VoucherByAdminRefScreen,
                icon: 'user',
                access: ['ADMIN'],
              },
              {
                name: 'VoucherByTinyId',
                label: translate('screens.vouchersByTinyId'),
                component: VoucherByTinyIdScreen,
                icon: 'voucher',
                access: ['ADMIN'],
              },
              {
                name: 'VouchersByEmail',
                label: translate('screens.vouchersByEmail'),
                component: VoucherByEmailScreen,
                icon: 'email',
                access: ['ADMIN'],
              },
              {
                name: 'Voucher-Item',
                label: translate('screens.voucherItem'),
                component: VoucherItemScreen,
                icon: 'voucher',
                nonMenu: true,
                access: ['ADMIN'],
              },
            ],
          },
        ],
      },
      {
        name: 'StaticData',
        icon: 'todo-list',
        access: ['ADMIN'],
        children: [
          {
            groupName: 'Data',
            groupLabel: translate('groups.data'),
            access: ['ADMIN'],
            list: [
              {
                name: 'Vessels',
                label: translate('screens.vessels'),
                component: VesselsScreen,
                icon: 'vessel-cog',
                access: ['ADMIN'],
              },
              // {
              //   name: 'Company data',
              //   label: translate('screens.companyData'),
              //   component: CompanyDataScreen,
              //   icon: 'ticket-offers',
              // },
              {
                name: 'Vessel-Item',
                label: translate('screens.vesselItem'),
                component: VesselItemScreen,
                icon: 'vessel-cog',
                nonMenu: true,
                access: ['ADMIN'],
              },
              {
                name: 'PortLocations',
                label: translate('screens.portLocations'),
                component: PortLocationsScreen,
                icon: 'area-v2',
                access: ['ADMIN'],
              },
              {
                name: 'PortLocation-Item',
                label: translate('screens.portLocationItem'),
                component: PortLocationItemScreen,
                icon: 'todo-list',
                nonMenu: true,
                access: ['ADMIN'],
              },

              {
                name: 'AreaLocations',
                label: translate('screens.areaLocations'),
                component: AreaLocationsScreen,
                icon: 'area',
                access: ['ADMIN'],
              },

              {
                name: 'AreaLocation-Item',
                label: translate('screens.areaLocationItem'),
                component: AreaLocationItemScreen,
                icon: 'todo-list',
                nonMenu: true,
                access: ['ADMIN'],
              },
            ],
          },
        ],
      },
      {
        name: 'DataManagement',
        icon: 'data',
        access: ['ADMIN', 'CONTENT'],
        children: [
          {
            groupName: 'DataManagement',
            groupLabel: translate('groups.dataManagement'),
            access: ['ADMIN', 'CONTENT'],
            list: [
              {
                name: 'DataManagementPanel',
                label: translate('screens.dataManagementPanel'),
                component: DataManagementPanelScreen,
                icon: 'clipboard-edit',
                singleTab: true,
                access: ['ADMIN', 'CONTENT'],
              },
            ],
          },
          {
            groupName: 'TrafficManager',
            groupLabel: translate('groups.trafficManager'),
            access: ['ADMIN'],
            list: [
              {
                name: 'TrafficManagerPanel',
                label: translate('screens.trafficManagerPanel'),
                component: TrafficManagerScreen,
                icon: 'alert-triangle',
                singleTab: true,
                access: ['ADMIN', 'CONTENT'],
              },
            ],
          },
          {
            groupName: 'SearchMeta',
            groupLabel: translate('groups.metaSearch'),
            access: ['ADMIN', 'CONTENT'],
            list: [
              {
                name: 'MetaSearchJobs',
                label: translate('screens.metaSearchJobs'),
                component: MetaSearchJobsScreen,
                icon: 'vessel-many',
                singleTab: true,
                access: ['ADMIN', 'CONTENT'],
              },
            ],
          },
        ],
      },
      {
        name: 'Notices',
        icon: 'bell',
        access: ['ADMIN'],
        children: [
          {
            groupName: 'Notices',
            groupLabel: translate('groups.notices'),
            access: ['ADMIN'],
            list: [
              {
                name: 'ActiveNotices',
                label: translate('screens.activeNotices'),
                component: ActiveNoticesScreen,
                icon: 'bell-active',
                singleTab: true,
                access: ['ADMIN'],
              },
              {
                name: 'PendingNotices',
                label: translate('screens.pendingNotices'),
                component: PendingNoticesScreen,
                icon: 'bell',
                singleTab: true,
                access: ['ADMIN'],
              },
              {
                name: 'Notice-Item',
                label: translate('screens.noticeItem'),
                component: NoticeItemScreen,
                icon: 'bell',
                platform: 'native',
                nonMenu: true,
                access: ['ADMIN'],
              },
            ],
          },
        ],
      },
    ] as ScreenDictionary
  ).filter(notEmpty);

const flattenScreens = (): ScreenList => {
  return Object.values(getScreenDictionary()).flatMap((screenGroup) => {
    if (screenGroup.children) {
      return screenGroup?.children?.flatMap((group) => group.list);
    }
    return screenGroup;
  });
};

const getDrawerScreenList = () => getScreenDictionary();

Globals.getScreenDictionary = getScreenDictionary;
Globals.flattenScreens = flattenScreens;
Globals.getDrawerScreenList = getDrawerScreenList;
