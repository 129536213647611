import { PageHeader } from '~/components/Page/Page.Header';
import { GetAdminUserWithActivityStatusResponse } from '../../Users.api';
import { translate } from '~/utils/translation.utils';

export const TeamOverViewHeader = ({
  managees,
}: {
  managees: GetAdminUserWithActivityStatusResponse[];
}) => {
  return (
    <PageHeader
      title={`${translate('supportTeam.overview')} ${managees.length} ${translate(
        'supportTeam.teamMembers',
      )}`}
    />
  );
};
