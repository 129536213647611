import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { TrafficManagerItemHeader } from './TrafficManager.itemHeader';
import { GetTrafficManagerQueueGroupResponse, systemApi } from '~/utils/system.api';
import { AppText } from '~/components/Text';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { ColorKeys } from '@droplet-tech-code/core-elements/module/theme';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { useEffect, useState } from 'react';
import { socketProvider } from '~/utils/socketProvider';

export const TrafficManagerItem = ({}: {}) => {
  const [data, setData] = useState({});
  const socket = socketProvider.getSocket();

  useEffect(() => {
    socket.emit('traffic-manager');
    socket.on('traffic-manager', (data) => {
      setData(data);
    });
  }, []);
  // const mockData = [
  //   {
  //     rateCounter: 10,
  //     activeCounter: 10,
  //     priorityCounter: 10,
  //     generalQueue: ['4'],
  //     priorityQueue: ['4'],
  //   },
  // ];
  // for (let i = 0; i < 15; i++) {
  //   mockData.push({
  //     rateCounter: 10,
  //     activeCounter: 10,
  //     priorityCounter: 10,
  //     generalQueue: ['4'],
  //     priorityQueue: ['4'],
  //   });
  // }
  if (!data) {
    return null;
  }
  return (
    <VStack space="2.5">
      <TrafficManagerItemHeader />
      <TrafficManagerItemInternal data={data} />
    </VStack>
  );
};

const TrafficManagerItemInternal = ({
  data,
}: {
  data: GetTrafficManagerMultiQueueGroupResponse;
}) => {
  return (
    <ScrollView style={{ height: 1100 }}>
      <VStack p="2.5" space="2.5" style={{ width: '100%' }}>
        {Object.entries(data).map(([queueGroupId, queueGroupData]) => (
          <QueueGroupData queueGroupId={queueGroupId} data={queueGroupData} />
        ))}
      </VStack>
    </ScrollView>
  );
};

const QueueGroupData = ({
  data,
  queueGroupId,
}: {
  data: GetTrafficManagerQueueGroupResponse;
  queueGroupId: string;
}) => {
  const { activeCounter, rateCounter, priorityCounter, priorityQueue, generalQueue } =
    data;

  const [resetActiveCounter, { isLoading: isResettingActive }] =
    systemApi.useTrafficManagerQueueGroupResetActiveCounterMutation();

  const [resetRateCounter, { isLoading: isResettingRate }] =
    systemApi.useTrafficManagerQueueGroupResetRateCounterMutation();

  const [resetPriorityCounter, { isLoading: isResettingPriority }] =
    systemApi.useTrafficManagerQueueGroupResetPriorityCounterMutation();

  const [popPriorityQueue, { isLoading: isPoppingPriority }] =
    systemApi.useTrafficManagerQueueGroupPopPriorityQueueMutation();

  const [popGeneralQueue, { isLoading: isPoppingGeneral }] =
    systemApi.useTrafficManagerQueueGroupPopGeneralQueueMutation();

  const [refreshQueueGroup, { isLoading: isRefreshing }] =
    systemApi.useTrafficManagerQueueGroupRefreshMutation();

  return (
    <HStack p="2.5" br={5} b={1} bc="monochrome-light" space="8">
      <AppText.SectionTitle style={{ width: '75px' }}>
        {queueGroupId}
      </AppText.SectionTitle>
      <QueueGroupCounter
        value={activeCounter}
        isLoading={isResettingActive}
        onPress={() => resetActiveCounter(queueGroupId)}
      />
      <Divider vertical />
      <QueueGroupCounter
        value={rateCounter}
        isLoading={isResettingRate}
        onPress={() => resetRateCounter(queueGroupId)}
      />
      <Divider vertical />
      <QueueGroupCounter
        value={priorityCounter}
        isLoading={isResettingPriority}
        onPress={() => resetPriorityCounter(queueGroupId)}
      />
      <Divider vertical />
      <HStack flex={1} space="2.5">
        <IconButton
          icon="arrow-up"
          color="error"
          variant="semi-filled"
          size="small"
          isLoading={isPoppingPriority}
          onPress={() => {
            popPriorityQueue(queueGroupId);
          }}
        />
        <QueueBar queue={priorityQueue} color="error-extraLight" />
        <IconButton
          icon="arrow-up"
          variant="semi-filled"
          size="small"
          isLoading={isPoppingGeneral}
          onPress={() => {
            popGeneralQueue(queueGroupId);
          }}
        />
        <QueueBar queue={generalQueue} color="primary-extraLight" />
      </HStack>
      <IconButton
        icon="refresh"
        onPress={() => refreshQueueGroup(queueGroupId)}
        isLoading={isRefreshing}
      />
    </HStack>
  );
};

const QueueGroupCounter = ({
  value,
  isLoading,
  onPress,
}: {
  value: number;
  isLoading: boolean;
  onPress: () => void;
}) => {
  const numWidth = '40px';

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <AppText.SectionTitle style={{ width: numWidth }} onPress={onPress}>
      {value}
    </AppText.SectionTitle>
  );
};

const QueueBar = ({ queue, color }: { queue: string[]; color: ColorKeys }) => {
  const queueLength = queue.length;

  const percentage = queueLength > 100 ? 100 : queueLength;

  return (
    <HStack bg="primary-base" space="2.5" p="2" flex={1}>
      <AppText.SectionTitle>{queueLength}</AppText.SectionTitle>
      <View bg={color} style={{ height: 10, width: `${percentage * 0.8}%` }} />
    </HStack>
  );
};

export interface GetTrafficManagerMultiQueueGroupResponse {
  [queueGroupId: string]: GetTrafficManagerQueueGroupResponse;
}
