import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { PageContainer } from '~/components/Page/Page.Container';
import { DataManagementPanelHeader } from './DataManagementPanel.header';
import { Card } from '~/components/Card/Card';
import { translate } from '~/utils/translation.utils';
import { systemApi } from '~/utils/system.api';
import { ScreenPropDataManagementPanel } from '~/navigation/Navigation.types';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { AppText } from '~/components/Text';
import { PressableCard } from '~/components/Card/PressableCard';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';

export const DataManagementPanelScreen = (props: ScreenPropDataManagementPanel) => {
  return (
    <PageContainer>
      <DataManagementPanel {...props} />
    </PageContainer>
  );
};

export const DataManagementPanel = (_: ScreenPropDataManagementPanel) => {
  const [sendEmail, { isLoading: isSending }] =
    systemApi.useLazySendMissingVesselsEmailQuery();

  const [updateCompany, { isLoading: isUpdatingCompany }] =
    systemApi.useRefreshCompanyDataCmsMutation();

  const onSend = async () => {
    const response = await sendEmail();
    handleResponse({ response, onError: () => {}, onSuccess: () => {} });
  };

  const onUpdateCompany = async (cleanFetch: boolean) => {
    const response = await updateCompany({ cleanFetch });
    handleResponse({ response, onError: () => {}, onSuccess: () => {} });
  };

  return (
    <VStack space="2.5">
      <DataManagementPanelHeader />
      <VStack p="2.5" space="2.5" style={{ width: '30%', minWidth: 300 }}>
        <Card h={50}>
          <HStack p="2.5" flex={1}>
            <AppText.MiscTag numberOfLines={1}>
              {translate('dataManagement.panel.panelSendMissingVessels')}
            </AppText.MiscTag>
            <View flex={1} />
            <PressableCard isLoading={isSending} onPress={() => onSend()} p="2.5">
              <Text.Small>{translate('dataManagement.panel.send')}</Text.Small>
            </PressableCard>
          </HStack>
        </Card>
        <Card h={50}>
          <HStack p="2.5" flex={1}>
            <AppText.MiscTag numberOfLines={1}>
              {translate('dataManagement.panel.expediteCompanyConfigUpdate')}
            </AppText.MiscTag>
            <View flex={1} />
            <PressableCard
              isLoading={isUpdatingCompany}
              onPress={() => onUpdateCompany(true)}
              p="2.5"
            >
              <Text.Small>{translate('dataManagement.panel.updateClean')}</Text.Small>
            </PressableCard>
            <PressableCard
              isLoading={isUpdatingCompany}
              onPress={() => onUpdateCompany(false)}
              p="2.5"
            >
              <Text.Small>{translate('dataManagement.panel.update')}</Text.Small>
            </PressableCard>
          </HStack>
        </Card>
      </VStack>
    </VStack>
  );
};
