import { io, Socket } from 'socket.io-client';
import { APP_URLS } from './constants.utils';
import { DefaultEventsMap } from '@socket.io/component-emitter';

class SocketProvider {
  private _socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
  private reconnectionInterval;

  socketInit = async (userId: string, token: string) => {
    if (this._socket) {
      return this._socket;
    }

    const socket = io(APP_URLS.BASE, {
      auth: {
        'auth-token': token,
      },
      transports: ['websocket'],
      path: '/socket.io',
      secure: true,
      reconnectionAttempts: 25, // Set the number of reconnection attempts
      reconnectionDelay: 1000, // Delay between reconnection attempts (in ms)
      reconnectionDelayMax: 5000, // Maximum delay between reconnections (in ms)
      randomizationFactor: 0.5, // Randomize the reconnection delay (0-1)
    });

    this._socket = socket;

    socket.on('connect', () => {
      console.log('connected');
      if (this.reconnectionInterval) {
        clearInterval(this.reconnectionInterval);
      }
      socket.emit('user-online', userId);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server, trying to reconnect...');
      this.reconnectionInterval = setInterval(async () => {
        if (this._socket?.connected) {
          clearInterval(this.reconnectionInterval);
        } else {
          console.log('trying to reconnect');
          if (this._socket) {
            this._socket.io._destroy(this._socket!); //destory old one
            this._socket = await this.socketInit(userId, token); //connect new
            this._socket?.connect();
          }
        }
      }, 5000); // every 5 seconds
    });
    socket.on('connect_error', (err) => {
      console.log(err);
    });
  };

  getSocket = () => {
    return this._socket!;
  };
}

export const socketProvider = new SocketProvider();
