import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { AppText } from '~/components/Text';
import { usersApi } from '../../Users.api';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { useUserInfoFromSocketIdModal } from './SupportTeamOverview.UserInfoModal';
import { AdminUser } from '@naus-code/naus-admin-types';

export const SocketInfo = () => {
  const { data, isLoading, refetch } = usersApi.useGetConnectedSocketsQuery(undefined, {
    pollingInterval: 3000,
  });

  if (!data) {
    return (
      <CardWithHeader headerText="Socket Info" w={700}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <AppText.ExtraSmall>{'No connected sockets'}</AppText.ExtraSmall>
        )}
      </CardWithHeader>
    );
  }
  return (
    <CardWithHeader
      headerText="Socket Info"
      w={700}
      iconRightType="refresh"
      iconRightOnPress={refetch}
    >
      <VStack p="2.5">
        {data?.map((socketId) => <SocketRowData socketId={socketId} />)}
      </VStack>
    </CardWithHeader>
  );
};

const SocketRowData = ({ socketId }: { socketId: string }) => {
  const { data, isLoading } = usersApi.useGetUserFromSocketIdQuery(socketId);
  const { open } = useUserInfoFromSocketIdModal();

  const UserNameComponent = ({
    data,
    isLoading,
  }: {
    data: AdminUser | undefined;
    isLoading: boolean | undefined;
  }) => {
    if (isLoading) {
      return <LoadingIndicator />;
    }
    const userName = !data ? 'not found' : data.name + ' ' + data.lastName;

    return <AppText.ExtraSmall flex={1}>{userName}</AppText.ExtraSmall>;
  };

  return (
    <HStack flex={1}>
      <AppText.ExtraSmall flex={1} onPress={() => open(socketId)}>
        {socketId}
      </AppText.ExtraSmall>
      <UserNameComponent data={data} isLoading={isLoading} />
    </HStack>
  );
};
